/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import { Fragment, useState, useEffect } from "react"
 import getFirebase from './Firebase/firebase'
 import FirebaseContext from './Firebase/firebasecontext';
 import withAuthentication from './Session/withAuthentication'
 import Footer from "./Footer"
 import styled from "styled-components"

 const StyledSiteWrapper = styled.main`
 display: flex;
 flex-direction: column;
 min-height: 100vh;
`

 const LayoutOB = (props) => {
   const [firebase, setFirebase] = useState();

   useEffect(() => {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const firestore = import('firebase/firestore');
    const functions = import('firebase/functions');

    Promise.all([app, auth, firestore, functions]).then(values => {
      const firebase = getFirebase(values[0]);
      setFirebase(firebase);
    })
   }, []);

   return(
    <FirebaseContext.Provider value={firebase}>
    <StyledSiteWrapper>
      <ContentWithAuthentication {...props}/>
      {
        (props.footerDisabled == null || props.footerDisabled == false) && <Footer location={props.location}  language={props.language} />
      }
      </StyledSiteWrapper>
    </FirebaseContext.Provider>
  )
 }

 const ContentWithAuthentication = withAuthentication(({children}) =>(
   <Fragment>
     <hr/>
     {children}
   </Fragment>
   ));

   export default LayoutOB;