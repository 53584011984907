import getStripe from "../../utils/stripe"
import * as ROUTES from "../../constants/constpaths.js"
import { Input } from "@material-ui/core"

const firebaseConfig = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  databaseUrl: process.env.GATSBY_DB_URL,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_APP_ID,
}

const userDBhandle = "users"
const salesDBhandle = "sales"
const pharmaciesDBhandle = "pharmacies"

class Firebase {
  constructor(app) {
    this.fb =
      app.default.apps.length === 0
        ? app.default.initializeApp(firebaseConfig)
        : app.default.apps[0]

    this.auth = this.fb.auth()
    this.fs = this.fb.firestore()
    this.functions = this.fb.functions("europe-west6") // prod=west6, dev=west1
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doChangeEmailAddress = (oldEmail, newEmail, password) =>
    this.auth
      .signInWithEmailAndPassword(oldEmail, password)
      .then(userCredential => {
        userCredential.user.updateEmail(newEmail)
      })

  doSignOut = () => {
    return this.auth.signOut()
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)
  /*
    doAddCAACData = (formCreAccAccCond) => {
        return new Promise((resolve, reject) => {
            this.auth.onAuthStateChanged(async (authUser) => {
                if (authUser) {
                    const docRef = this.fs.collection(userDBhandle).doc(authUser.uid);
                    const cd = new Date();
                    await docRef.set({
                        email: authUser.email,
                        user_id: authUser.uid,
                        firstName: formCreAccAccCond.fname,
                        lastName: formCreAccAccCond.lname,
                        birthday: formCreAccAccCond.birthdate,
                        confirmDataProcessing: formCreAccAccCond.acceptedPersonalData,
                        confirmTermsOfUse: formCreAccAccCond.acceptedGeneralConditions,
                        confirmNews: formCreAccAccCond.acceptedNewsletter,
                        user_lastmodified: cd
                    }).then(() => {
                        resolve();
                    })
                        .catch((e) => {
                            console.error("Error", e)
                            reject();
                        });;
                }
            })
        });
    }
*/
  /*
    doAddUserData = (formCreateAccount, formDeliveryAccept) => {
        return new Promise((resolve, reject) => {
            this.auth.onAuthStateChanged(async (authUser) => {
                if (authUser) {
                    // should be "await"
                    const docRef = this.fs.collection(userDBhandle).doc(authUser.uid);
                    const ca = new Date();
                    await docRef.update({
                        email: authUser.email,
                        user_id: authUser.uid,
                        firstName: formCreateAccount.fname,
                        lastName: formCreateAccount.lname,
                        birthday: formCreateAccount.birthdate,
                        address: formDeliveryAccept.add_str + " " + formDeliveryAccept.add_nr,
                        city: formDeliveryAccept.add_city + " " + formDeliveryAccept.add_zc,
                        Country: formDeliveryAccept.country,
                        confirmDataProcessing: formDeliveryAccept.acceptedPersonalData,
                        confirmTermsOfUse: formDeliveryAccept.acceptedGeneralConditions,
                        confirmNews: formDeliveryAccept.acceptedNewsletter,
                        startDate: formDeliveryAccept.start_date,
                        user_lastmodified: ca
                    }).then(() => {
                        resolve();
                    })
                        .catch((e) => {
                            console.error("Error", e)
                            reject();
                        });;
                }
            })
        });
    };
*/
  /*
doUpdateUserDataDIPI = (formDIPI, inWebFlow) => {

    let countrycode = formDIPI.country === 'Schweiz' ? 'ch' : 'de';
    const today = new Date();
    const oneMonthAfterTrialStart = new Date(formDIPI.start_date.getFullYear(), formDIPI.start_date.getMonth() + 1, formDIPI.start_date.getDate(), today.getHours());

    const authUser = this.auth.currentUser;
    return new Promise(async (resolve, reject) => {
        if (authUser) {
            // should be "await"
            const docRef = this.fs.collection(userDBhandle).doc(authUser.uid);
            const docRef_sales = this.fs.collection(salesDBhandle).doc(authUser.uid);
            if (inWebFlow) { // update/initialize birthdate
                await docRef.update({
                    startDate: formDIPI.start_date,
                    birthday: formDIPI.birthdate,
                    firstName: formDIPI.fname,
                    lastName: formDIPI.lname,
                    address: formDIPI.add_str + " " + formDIPI.add_nr,
                    city: formDIPI.add_zc + " " + formDIPI.add_city,
                    country: formDIPI.country,
                    countryCode: countrycode,
                })
                    .then(() => {
                        resolve();
                    })
                    .catch((e) => {
                        console.error("Error", e)
                        reject();
                    });

                await docRef_sales.update({
                    acquisitionChannel: formDIPI.acq_ch,
                    trialSignupLocation: 'web',
                    trialSignupDate: today,
                    trialOverDate: oneMonthAfterTrialStart,
                    test: 'testwebflow',
                }).then(() => {
                    resolve();
                })
                    .catch((e) => {
                        console.error("Error", e)
                        reject();
                    });
            }
            else {// don't update birthdate
                await docRef.update({
                    startDate: formDIPI.start_date,
                    firstName: formDIPI.fname,
                    lastName: formDIPI.lname,
                    address: formDIPI.add_str + " " + formDIPI.add_nr,
                    city: formDIPI.add_zc + " " + formDIPI.add_city,
                    country: formDIPI.country,
                    countryCode: countrycode,
                    TrialSignupLocation: 'web',
                    TrialSignupDate: today,
                    TrialOverDate: oneMonthAfterTrialStart,
                }).then(() => {
                    resolve();
                })
                    .catch((e) => {
                        console.error("Error", e)
                        reject();
                    });


                await docRef_sales.update({
                    acquisitionChannel: formDIPI.acq_ch,
                    trialSignupLocation: 'mobile',
                    trialSignupDate: today,
                    trialOverDate: oneMonthAfterTrialStart,
                    test: 'testmobileflow',
                }).then(() => {
                    resolve();
                })
                    .catch((e) => {
                        console.error("Error", e)
                        reject();
                    });
            }
        }
    }
);*/

  doAddPharmacyId = form => {
    //let countrycode = formDIPI.country === 'Schweiz' ? 'ch' : 'de';

    const authUser = this.auth.currentUser
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)
          await docRef
            .update({
              pharmacyId: form.pharmacyId,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doAddUserPharmacyData = form => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)
          await docRef
            .update({
              pharmacyId: form.pharmacy,
              healthCardNumber: form.cardNumber,
              healthCardHolder: form.cardHolder,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doAddUserSurveyData = data => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        console.log(authUser.uid)
        if (authUser) {
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)
          await docRef
            .update({
              hearFrom: data,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doGetPharmacyList = canton => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          var collection = await this.fs
            .collection(pharmaciesDBhandle)
            .where("canton", "in", [canton, "ALL"])

          await collection
            .get()
            .then(docs => {
              var data = []
              docs.forEach(doc => {
                data.push({
                  id: doc.id,
                  data: doc.data(),
                })
              })

              if (docs.size == data.length) {
                resolve(Promise.all(data))
              }
            })
            .catch(error => {
              console.error("Error getting document:", error)
              reject()
            })
        }
      })
    })
  }

  doUpdateUserStatus = () => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .update({
              status: "testPeriod",
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }
  doAddSalesChannelInfo = (form, inWebFlow) => {
    const today = new Date()
    let location = ""
    if (inWebFlow) {
      location = "web"
    } else {
      location = "mobile"
    }
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef_sales = this.fs
            .collection(salesDBhandle)
            .doc(authUser.uid)

          await docRef_sales
            .set(
              {
                acquisitionChannel: form.salesChannel,
                acquisitionChannelOther: form.salesChannelOther,
                trialSignupLocation: location,
                trialSignupDate: today,
                voucherCode: form.voucherCode,
              },
              { merge: true }
            )
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doUpdateOnboardingSalesInfo = (form, inWebFlow) => {
    const today = new Date()
    let location = ""
    if (inWebFlow) {
      location = "web"
    } else {
      location = "mobile"
    }
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"

          const docRef_sales = this.fs
            .collection(salesDBhandle)
            .doc(authUser.uid)
          await docRef_sales
            .set(
              {
                OnboardingLocation: location,
                OnboardingCompletedDate: today,
                medicationLeft: form.medicationLeft,
                startNow: form.startNow,
                sendReminder: form.sendReminder,
              },
              { merge: true }
            )
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }
  doUpdateOnboardingLocation = () => {
    const today = new Date()

    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"

          const docRef_sales = this.fs
            .collection(salesDBhandle)
            .doc(authUser.uid)
          await docRef_sales
            .set(
              {
                OnboardingLocation: "web",
                OnboardingCompletedDate: today,
              },
              { merge: true }
            )
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doSetOnboardingComplete = () => {
    const today = new Date()
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .update({
              onboardingCompleted: true,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doUpdateOnboardingUserInfo = (formPharmacy, formAddress, formMedication) => {
    const today = new Date()
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .update({
              address: formAddress.address,
              city: formAddress.city,
              zipCode: formAddress.zipCode,
              phone: formAddress.phonenumber,
              gender: formPharmacy.gender,
              pregnant:
                formPharmacy.pregnant === "" ? "Nein" : formPharmacy.pregnant,
              diseases: formPharmacy.diseases,
              allergies:
                formPharmacy.allergies === "Ja"
                  ? formPharmacy.whichAllergies
                  : formPharmacy.allergies,
              medications:
                formPharmacy.otherMedications === "Ja"
                  ? formPharmacy.whichOtherMedications
                  : formPharmacy.otherMedications,
              startDate: formMedication.startDate,
              OnboardingCompleted: true,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doUpdateStartDate = form => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .update({
              startDate: form.startDate,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doUpdateInsuranceInfo = form => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .update({
              healthCardNumber: form.cardNumber,
              insuranceCompany: form.insuranceName,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }
  doUpdateMedicationSalesInfo = form => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"

          const docRef_sales = this.fs
            .collection(salesDBhandle)
            .doc(authUser.uid)
          await docRef_sales
            .set(
              {
                medicationLeft: form.medicationLeft,
                startNow: form.startNow,
              },
              { merge: true }
            )
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error: ", e)
              reject()
            })
        }
      })
    })
  }

  doUpdateStep = input => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef_sales = this.fs
            .collection(salesDBhandle)
            .doc(authUser.uid)
          await docRef_sales
            .set(
              {
                lastFinishedStep: input,
              },
              { merge: true }
            )
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error: ", e)
              reject()
            })
        }
      })
    })
  }
  doCreateStep = input => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef_sales = this.fs
            .collection(salesDBhandle)
            .doc(authUser.uid)
          await docRef_sales
            .set({
              lastFinishedStep: input,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error: ", e)
              reject()
            })
        }
      })
    })
  }
  doUpdateAddress = form => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .update({
              address: form.street,
              city: form.city,
              zipCode: form.zipCode,
              phone: form.phonenumber,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doUpdatePharmacyInfo = form => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .update({
              gender: form.gender,
              pregnant: form.pregnant === "" ? "Nein" : form.pregnant,
              diseases: form.diseases,
              allergies:
                form.allergies === "Ja" ? form.whichAllergies : form.allergies,
              medications:
                form.otherMedications === "Ja"
                  ? form.whichOtherMedications
                  : form.otherMedications,
              pharmacyId: form.pharmacyId,
            })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doAddPhysiciansSalesInfo = input => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          // should be "await"
          const docRef = this.fs.collection(salesDBhandle).doc(authUser.uid)

          await docRef
            .set(input, { merge: true })
            .then(() => {
              resolve()
            })
            .catch(e => {
              console.error("Error", e)
              reject()
            })
        }
      })
    })
  }

  doGetUserData = () => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          const docRef = this.fs.collection(userDBhandle).doc(authUser.uid)

          await docRef
            .get()
            .then(doc => {
              resolve(doc.data())
            })
            .catch(error => {
              console.error("Error getting document:", error)
              reject()
            })
        }
      })
    })
  }
  doGetSalesData = () => {
    return new Promise(async (resolve, reject) => {
      this.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          const docRef = this.fs.collection(salesDBhandle).doc(authUser.uid)

          await docRef
            .get()
            .then(doc => {
              resolve(doc.data())
            })
            .catch(error => {
              console.log("Error getting document:", error)
              reject()
            })
        }
      })
    })
  }

  doStripeRedirect = async (
    authuser,
    recurringprice,
    startupprice,
    country,
    mail,
    taxrate,
    daystillfirstdelivery
  ) => {
    const parsedauth = JSON.parse(authuser)
    const cancelPath = window.location.origin + ROUTES.CANCEL
    const successPath = window.location.origin + ROUTES.SUCCESS
    const docref = await this.fs
      .collection(userDBhandle)
      .doc(parsedauth.user_id)
      .collection("checkout_sessions")
      .add({
        line_items: [
          {
            price: recurringprice, // RECURRING_PRICE_ID
            quantity: 1,
            tax_rates: [taxrate],
          },
        ],
        billing_address_collection: "auto",
        trial_period_days: 60 + daystillfirstdelivery,
        allow_promotion_codes: true,
        success_url: successPath,
        cancel_url: cancelPath,
      })

    docref.onSnapshot(async snap => {
      const { error, sessionId } = snap.data()
      if (error) {
        alert(`An error occured: ${error.message}`)
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await getStripe()
        stripe.redirectToCheckout({ sessionId })
      }
    })
  }

  doStripeRedirectNew = async (authuser, recurringprice, taxrate) => {
    console.log("doStripeRedirectCalled")
    const parsedauth = JSON.parse(authuser)
    const cancelPath = window.location.origin + ROUTES.CANCEL
    const successPath = window.location.origin + ROUTES.SUCCESS

    console.log(parsedauth.user_id)
    console.log(recurringprice)
    console.log(taxrate)
    const docref = await this.fs
      .collection(userDBhandle)
      .doc(parsedauth.user_id)
      .collection("checkout_sessions")
      .add({
        line_items: [
          {
            price: recurringprice, // RECURRING_PRICE_ID
            quantity: 1,
            tax_rates: [taxrate],
          },
        ],
        billing_address_collection: "auto",
        allow_promotion_codes: true,
        success_url: successPath,
        cancel_url: cancelPath,
      })

    docref.onSnapshot(async snap => {
      const { error, sessionId } = snap.data()
      if (error) {
        alert(`An error occured: ${error.message}`)
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await getStripe()
        stripe.redirectToCheckout({ sessionId })
      }
    })
  }
  doRedirectToCustomerPortal = async () => {
    const functionRef = this.functions.httpsCallable(
      "ext-firestore-stripe-subscriptions-createPortalLink"
    )
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      allow_promotion_codes: true,
    })
    window.location.assign(data.url)
  }

  isUserLoggedIn = () => {
    var user = this.auth.currentUser
    return !(user === null)
  }

  /*
    This function "subscribes" to authStateChanged by passing it callbacks functions
    It takes two functions as arguments that are then called on authentication state changes
    1) If there is a user, the corresponding document is fetch and, upon success, "next" is called
    2) If there isn't a user, "fallback" is called
*/
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      // onAuthStateChanged returns the user and the related info, provided he/she's sign-in
      // it is called on every sign-in status change
      if (authUser) {
        var docFS = this.fs.collection(userDBhandle).doc(authUser.uid)
        docFS
          .get()
          .then(doc => {
            if (doc.exists) {
              const dbUser = doc.data()
              authUser = {
                email: authUser.email,
                user_id: authUser.uid,
                user_emailVerified: authUser.emailVerified,
                user_providerData: authUser.providerData,
                ...dbUser,
              }
              next(authUser)
            } else {
              console.error("Error: database document was not found")
            }
          })
          .catch(e => console.error("Error writing to database :" + e))
      } else {
        fallback()
      }
    })

  user = uid => this.fs.collection(userDBhandle).doc(uid)
  sales = uid => this.fs.collection(salesDBhandle).doc(uid)
}

let firebase

function getFirebase(app, auth, database, firestore) {
  if (typeof window != "undefined") {
    if (!firebase) {
      firebase = new Firebase(app, auth, database, firestore)
    }
    return firebase
  }
  return null
}

export default getFirebase
