import React from 'react';

import { withFB } from '../Firebase/firebasecontext';
import AuthUserContext from './sessioncontext.js';

export const authUserHandle = 'authUser';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        _initFirebase = false;

        constructor(props) {
            super(props);

            this.state = {
                authUser: null,
            };
        }

        firebaseInit = () => {
            if (this.props.firebase && !this._initFirebase) {
                this._initFirebase = true;

                // this function is defined in firebase.js, takes two functions as parameter
                // it sets a listener that is unmounted after use
                // the first function "next" is passes saves the user on local storage and sets the state
                // the second function "fallback" removes user from local storage and sets the state
                // the "this.listener" object is a function that, when called, unsubscribes from the subscription
                this.listener = this.props.firebase.onAuthUserListener(
                    authUser => {
                        sessionStorage.setItem(
                            authUserHandle,
                            JSON.stringify(authUser),
                        );
                        this.setState({ authUser });
                    },
                    () => {
                        sessionStorage.removeItem(authUserHandle);
                        this.setState({ authUser: null });
                    }
                );
            }
        };

        componentDidMount() {
            this.setState({
                authUser: JSON.parse(localStorage.getItem(authUserHandle)),
            });
            this.firebaseInit(); // sets the listener
        };

        componentDidUpdate() {
            this.firebaseInit(); // sets the listener
        };

        componentWillUnmount() {
            this.listener && this.listener(); // cheeky: if listener initialized, calls the listener defined above.
        };

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }
    return withFB(WithAuthentication);
};

export default withAuthentication;