import React from 'react'

// this context contains Firebase info and is passed down the react component tree
const FirebaseContext = React.createContext(null);

export const withFB = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase}/>}
    </FirebaseContext.Consumer>
);

export default FirebaseContext;